import { useState, useEffect } from "react";
import { getSessionInfo } from "../services/requests";

export const useSessionInfo = () => {
    const [sessionInfo, setSessionInfo] = useState(null);

    useEffect(() => {
        loadSession();
    }, []);

    const loadSession = async () => {
        const res = await getSessionInfo();
        setSessionInfo(res);
    };

    return sessionInfo;
};
