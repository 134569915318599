import styles from './styles/leftForm.module.scss';
import AppContext from '../data/AppContext';
import { useContext } from 'react';
import { maskMoney } from '../utils/Currency';

const LeftForm = () => {
	const { sessionInfo } = useContext(AppContext);

	const goBack = () => {
		if(sessionInfo?.backUrl?.back) {
			window.location.href = sessionInfo.backUrl.back;
		}
	};

	return (
		<div className={styles.root}>
			<div className={styles.backBox} onClick={goBack}>
				<p className={styles.backArrow}>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgba(0,0,60,.6)" fill="none" strokeLinecap="round" strokeLinejoin="round">
						<path stroke="none" d="M0 0h24v24H0z" fill="none" />
						<line x1="5" y1="12" x2="19" y2="12" />
						<line x1="5" y1="12" x2="11" y2="18" />
						<line x1="5" y1="12" x2="11" y2="6" />
					</svg>
				</p>
				<p className={styles.backText}>Expendr</p>
			</div>
			<p className={styles.amountLabel}>{sessionInfo?.plan?.name}</p>
			<p className={styles.amount}>{maskMoney(sessionInfo?.plan?.price)}</p>
			<p className={styles.paymentFrequency}>por mes</p>
			<img className={styles.image} src="https://i.ibb.co/rb4cS7s/Group-40-2-1.webp" />
		</div>
	);
};

export default LeftForm;
