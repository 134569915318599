import { useContext } from 'react';
import AppContext from '../data/AppContext';
// Styles
import styles from './styles/rightForm.module.scss';

//Components
import HolderInput from './inputs/HolderInput';
import CardInput from './inputs/CardInput';
import CardTypeInput from './inputs/CardTypeInput';

const RightForm = (props) => {
	const { success } = useContext(AppContext);
	const { expired } = props;

	return (
		<div className={styles.root}>
			<div className={styles.form}>
				{
					success && !expired &&
					<>
						<svg className={styles.checkIcon} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="100" height="100" viewBox="0 0 24 24" strokeWidth="0.5" stroke="#00b341" fill="none" strokeLinecap="round" strokeLinejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<circle cx="12" cy="12" r="9" />
							<path d="M9 12l2 2l4 -4" />
						</svg>
						<h2
							style={{ textAlign: "center" }}
							className={styles.title}
						>
							Pago realizado con éxito!
						</h2>
					</>
				}
				{
					!success && !expired &&
					<>
						<h2 className={styles.title}>Detalle de pago</h2>
						<CardTypeInput />
						<HolderInput />
						<CardInput />
					</>
				}
				{
					expired &&
					<>
						<svg className={styles.checkIcon} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-x" width="100" height="100" viewBox="0 0 24 24" strokeWidth="0.5" stroke="#ff2825" fill="none" strokeLinecap="round" strokeLinejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<circle cx="12" cy="12" r="9" />
							<path d="M10 10l4 4m0 -4l-4 4" />
						</svg>
						<h2
							style={{ textAlign: "center" }}
							className={styles.title}
						>
							Link expirado o inválido
						</h2>
					</>
				}
			</div>
		</div >
	);
};

export default RightForm;
