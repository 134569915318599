import styles from './styles/input.module.scss';
import { useContext, useEffect, useState } from 'react';
import AppContext from '../../data/AppContext';
import images from 'react-payment-inputs/images';

export const validateEmail = (email) => {
	if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
		return (true);
	}
	return (false);
};

const CardInput = () => {
	const { paymentFields, setField, handlePayment, values } = useContext(AppContext);
	const {
		meta,
		getCardNumberProps,
		getExpiryDateProps,
		getCVCProps,
		getCardImageProps
	} = paymentFields;
	const [hasError, setHasError] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [disabled, setDisabled] = useState(false);

	const showError = meta?.isTouched && meta?.error || (meta?.error && hasError);

	useEffect(() => {
		if(values.holderName && values.dni && values.email) {
			setErrorMsg('');
		}
	}, [values.holderName, values.dni, values.email]);

	const handleChange = (e, inputName) => {
		setField(inputName, e?.target?.value);
		setHasError(false);
		setErrorMsg('');
	};

	const handleKeyPress = (e) => {
		if(e.keyCode === 9 && meta?.error) {
			setHasError(true);
		}
	};

	const disableButton = () => {
		setDisabled(true);
		setTimeout(() => {
			setDisabled(false);
		}, 3000);
	};

	const handleButtonClick = () => {
		if(showError || !values.cardNumber || !values.paymentMethod) {
			setErrorMsg("Ingresa los datos de la tarjeta");
			return;
		}
		if(values.holderName?.length < 4) {
			setErrorMsg("Ingresa el nombre");
			return;
		}
		if(values.dni?.length < 6) {
			setErrorMsg("Ingresa un documento de identidad válido");
			return;
		}
		if(!validateEmail(values.email)) {
			setErrorMsg("Ingresa un email válido");
			return;
		}
		handlePayment();
		disableButton();
	};

	return (
		<>
			<label className={styles.label}>Tarjeta</label>
			<div className={showError ? styles.cardInputContainerWithError : styles.cardInputContainer}>
				{
					!showError && errorMsg?.length > 0 &&
					<span className={styles.cardErrorMsg}>Error: {errorMsg}</span>
				}
				<svg className={styles.cardImage} {...getCardImageProps({ images })} />
				<input
					{...getCardNumberProps({ onChange: (e) => handleChange(e, 'cardNumber') })}
					className={styles.cardInput}
					type="text"
					placeholder='1234 1234 1234 1234'
					onKeyDown={handleKeyPress}
				/>
				<input
					{...getExpiryDateProps({ onChange: (e) => handleChange(e, 'expiryDate') })}
					className={styles.dateInput}
					type="text"
					placeholder='MM / AA'
					onKeyDown={handleKeyPress}
				/>
				<input
					{...getCVCProps({ onChange: (e) => handleChange(e, 'cvc') })}
					className={styles.cvcInput}
					type="text"
					placeholder='CVC'
					onKeyDown={handleKeyPress}
				/>
				{
					showError &&
					<span className={styles.cardErrorMsg}>Error: {meta.error}</span>
				}
				<button
					disabled={disabled}
					onClick={handleButtonClick}
				>
					Suscribirse
				</button>
			</div>
		</>
	);
};

export default CardInput;
