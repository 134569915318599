export const ERROR_MESSAGES = {
    emptyCardNumber: 'Ingresa el número de la tarjeta',
    invalidCardNumber: 'El número de la tarjeta es inválido',
    emptyExpiryDate: 'Ingresa la fecha de expiración de la tarjeta',
    monthOutOfRange: 'El mes de expiración debe estar entre 01 y 12',
    yearOutOfRange: 'El año de expiración no puede estar en el pasado',
    dateOutOfRange: 'La fecha de expiración no puede estar en el pasado',
    invalidExpiryDate: 'La fecha de expiración es inválida',
    emptyCVC: 'Ingresa un código de seguridad (CVC)',
    invalidCVC: 'El código de seguridad (CVC) es inválido'
};
