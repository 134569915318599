import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL;

const search = window.location.search;
const params = new URLSearchParams(search);
const instanceToken = params.get('sId');

export const getSessionInfo = async () => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/checkout/${instanceToken}`,
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response;
    });
};

export const sendPayment = async (payload) => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/checkout/subscription/${instanceToken}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response;
    });
};
