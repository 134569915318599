import styles from './styles/input.module.scss';
import { useContext } from 'react';
import AppContext from '../../data/AppContext';

const HolderInput = () => {
	const { values, setField } = useContext(AppContext);

	const onDniChange = (e) => {
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value)) {
			if(e.target.value?.length > 9) return;
			setField('dni', e?.target?.value);
		}
	}

	return (
		<>
			<label className={styles.label}>Titular de la tarjeta</label>
			<div>
				<input
					value={values.holderName}
					onChange={((e) => { setField('holderName', e?.target?.value); })}
					className={styles.nameInput}
					type="text"
					placeholder='Nombre Completo'
				/>
				<input
					value={values.email}
					className={styles.emailInput}
					onChange={((e) => { setField('email', e?.target?.value); })}
					type="text"
					placeholder='Email'
				/>
				<input
					value={values.dni}
					className={styles.dniInput}
					onChange={onDniChange}
					type="text"
					placeholder='DNI'
				/>
			</div>
		</>
	);
};

export default HolderInput;
