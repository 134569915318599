import styles from './styles/input.module.scss';
import { useContext } from 'react';
import AppContext from '../../data/AppContext';

const OptionsInput = (props) => {
	const { values, setField } = useContext(AppContext);
	const { label, options } = props;

	return (
		<>
			<label className={styles.label}>{label}</label>
			<div className={styles.optionsContainer}>
				{
					options.map(option => {
						return <div
							key={option.value}
							className={values.paymentMethod === option.value ? styles.selectedOptionBox : styles.optionBox}
							onClick={() => setField('paymentMethod', option.value)}
						>
							{option.text}
						</div>;
					})
				}
			</div>
		</>
	);
};

export default OptionsInput;
