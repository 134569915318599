//Components
import OptionsInput from './OptionsInput';

const options = [
    { value: 'credit', text: 'Crédito' },
    { value: 'debit', text: 'Débito' }
];

const RightForm = () => {
    return (
        <OptionsInput
            options={options}
            label="Método de pago"
        />
    );
};

export default RightForm;
