/*global Zenrise*/
import { useEffect, useState } from "react";

// Hooks
import { usePaymentInputs } from 'react-payment-inputs';
import { ERROR_MESSAGES } from '../appConstants';

// Requests
import { sendPayment } from "../services/requests";

const initialState = {
    paymentMethod: 'credit',
    cardNumber: '',
    cvc: '',
    expiryDate: '',
    holderName: '',
    dni: '',
    email: ''
};


export const useAppState = () => {
    const paymentFields = usePaymentInputs({
        errorMessages: ERROR_MESSAGES
    });
    const [values, setFieldValues] = useState(initialState);
    const [success, setSuccess] = useState(false);

    const setField = (name, value) => {
        setFieldValues({ ...values, [name]: value });
    };

    function getCardData() {
        const holderId = {
            type: "dni",
            number: values.dni
        };
        return {
            card_number: values.cardNumber,
            card_expiration_month: values.expiryDate?.split('/')[0]?.trim(),
            card_expiration_year: values.expiryDate?.split('/')[1]?.trim(),
            security_code: values.cvc,
            card_holder_name: values.holderName,
            card_holder_identification: holderId
        };
    }

    const handlePayment = async () => {
        // Generate token
        if(!Zenrise) return;
        const zenrise = new Zenrise.Sdk(process.env.REACT_APP_ZENRISE_ENV, 3);
        const paymentToken = await zenrise.getCardToken(getCardData());

        // Send payment request
        const response = await sendPayment({
            bin: paymentToken.bin,
            lastFourDigits: paymentToken.lastFourDigits,
            token: paymentToken.token,
            creditCard: values.paymentMethod === 'credit'
        });

        // Show Feedback
        if(response.status > 299) {
            alert("Error al realizar el pago");
            return;
        }
        setSuccess(true);
    };

    return { values, setField, handlePayment, paymentFields, success };
};
