import './App.scss';
import AppContext from './data/AppContext';
import React, { useState, useEffect } from 'react';

//Components
import LeftPanel from './components/LeftPanel';
import PaymentForm from './components/PaymentForm';

// State
import { useAppState } from './hooks/useAppState';
import { useSessionInfo } from './hooks/useSessionInfo';

const AppSkeleton = () => {
  const appState = useAppState();
  const sessionInfo = useSessionInfo();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log({ isLoading });
    setTimeout(() => {
      setIsLoading(false);
      console.log({ isLoading });
    }, 2000);
  }, []);

  return (
    <AppContext.Provider value={{ sessionInfo, ...appState }}>
      {
        (isLoading || sessionInfo && sessionInfo?.expired !== true) && <>
          <div key="app" className="app">
            <div className="leftBackground">
              <LeftPanel />
            </div>
            <div className="rightBackground">
              <PaymentForm />
            </div>
          </div>
        </>
      }
      {
        !isLoading && (!sessionInfo || sessionInfo?.expired === true) && <>
          <div className="app">
            <div className="leftBackground">
              {/* <LeftPanel /> */}
            </div>
            <div className="rightBackground">
              <PaymentForm expired={true} />
            </div>
          </div>
        </>
      }
    </AppContext.Provider>
  );
};

export default AppSkeleton;
